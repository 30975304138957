import React from "react"

import Layout from "../../components/layout"
import SubServices from "../../components/screens/Services/SubServices"
import { Helmet } from "react-helmet"

const OutsourcingPage = () => (
  <Layout pageInfo={{ pageName: "Outsourcing" }} sitePage="site-page">
    <Helmet defer={false}>
        <title>Outsourcing</title>
        <meta name="description" content="Outsourcing" />
    </Helmet>
    <h1 className="name-sub-services">Outsourcing</h1>
    <div className="wrapper-box-service">
      <SubServices
        left
        image={require("../../images/jpg/sub-services-10.jpg")}
        normal="Vietnam has fast emerged as the outsourcing destination of choice. OnlineBiz Soft provide superior outsourced development services that ensure effective communication to incorporate all your requirements, accelerated development so your products are launched on time and cost effectiveness so that development remains within your budget."
      />
    </div>
  </Layout>
)

export default OutsourcingPage
